
                    @import "@/css/ajax-rendering/admin-lte/_variables.scss";
                
@import '/css/Demonstrare4/mixins';


$rentButtonTextColor: #000000;
$rentButtonColor: #cfe9f6;
$rentButtonHoverColor: #cfe906;

.cart-small-orpaynow-txt, .cart-small-or-leasing-txt {
    font-weight: normal;
    display: inline-block;
    font-size:12px;
}

.cart-small-orpaynow-total, .cart-small-total-leasing-cost {
    font-weight: normal;
    font-size: 12px;
    text-decoration:underline;
    cursor:pointer;
}

.d4-preview-container .ad-buy-button.nordea-leasing-btn {
    background-color: #cfe9f6;
    color: black;
}
.nordea-or-text {
    text-align: center;
    width: calc(100% - 50px);
}

.ProductInfo {
    & .nordea-or-text {
        text-align: center;
        width: 100%;
    }
}

.cart-leasing-fee .popover{
    min-width:260px;
}

.ProductInfo {

    @include fromSizeSM {
        .nordea-rent-product {
            width: 100%;
            padding: 15px;
            text-transform: none;
            font-size: 22px;
            color: $rentButtonTextColor;
            background: $rentButtonColor;

            &:hover {
                background: $rentButtonHoverColor;
            }
        }
    }
}